import Image from "next/legacy/image";
import Link from "next/link";

import { asanteKotokoLogo } from "../utils/constants/images";
import { INDEX_URL } from "../utils/constants/websiteUrls";
import NoTextNavBackground from "../components/common/NoTextNavBackground";
import RightArrowIcon from "../components/common/icons/RightArrowIcon";
import { customStaticSeoConfig } from "../utils/helpers/helpers";

const NotFound = () => {
  return <>
    {customStaticSeoConfig("Not Found")}

    <NoTextNavBackground />

    <main className="relative py-[120px]  sm:py-[275px] md:py-[220px] ">
      <div className="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
        <div className="flex-shrink-0 flex justify-center items-center">
          <Link href={INDEX_URL} className="inline-flex">

            <span className="sr-only">Asante Kotoko SC</span>
            <div className="relative h-12 w-12">
              <Image
                src={asanteKotokoLogo}
                alt="asante kotoko logo"
                layout="fill"
                objectFit="contain"
                quality={100}
                priority
              />
            </div>

          </Link>
        </div>
        <div className="py-10">
          <div className="text-center">
            <p className="text-sm font-semibold text-red-600 dark:text-green-800 uppercase tracking-wide">
              404 error
            </p>
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              Page not found.
            </h1>
            <p className="mt-2 text-base text-gray-500">
              {"Sorry, we couldn't find the page you're looking for."}
            </p>
            <div className="mt-6">
              <Link
                href={INDEX_URL}
                className="text-base font-medium text-red-600 hover:text-red-500 dark:text-green-800  dark:hover:dark:text-green-700">
                Go back home<span aria-hidden="true">
                  <RightArrowIcon />
                </span>

              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  </>;
};

export default NotFound;
